.messages {
    height: 600px;
    /* overflow-y: auto; */
    overflow-x: hidden;
    flex: 1;
    scroll-behavior: smooth;
}


.chat-window {
    background-color: #fff; /* светлый фон */
    color: #333; /* темный текст */
    flex: 1;
    padding: 10px;
    /* добавьте здесь стили для окна чата */
}


.chat-window-submit-button {
    margin-top: 10px;
    background-color: #222;
    color: #fff;
    padding: 10px;
    border: none;
    cursor: pointer;
    font-weight: bold;
    border-radius: 1rem; /* Закругление углов на кнопке */
  }

  ::-webkit-scrollbar {
    width: 8px; /* ширина скролла */
    background-color: #f5f5f5; /* цвет фона скролла */
  }
  
  /* Стилизация ползунка скролла */
  ::-webkit-scrollbar-thumb {
    background-color: #999; /* цвет ползунка скролла */
    border-radius: 4px; /* радиус закругления углов ползунка скролла */
  }
  
  /* Изменение цвета ползунка при наведении */
  ::-webkit-scrollbar-thumb:hover {
    background-color: #666; /* новый цвет ползунка при наведении */
  }

.attachment-container {
  position: relative;
  margin: 10px 0;
}

.attachment-input {
  display: none;
}

.attachment-label {
  padding: 10px 20px;
  margin: 0.5rem 0;
  size: 100%;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.attachment-info {
  margin-top: 10px;
  text-align: center;
}

.logo-header {
  max-width: 7em!important;
}

.msg-cont {
  padding-left: 0;
  padding-right: 0;
  border-color: #dc3545;
  border-radius: .25rem;
  border: 0.25em outset red;
  background: #fff;
  transition: .5s;
  /* margin-bottom: 30px; */
  border-radius: .55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}

.msg-input


.chat-app  {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 7
}

.chat-app .chat {
  
  border-left: 1px solid #eaeaea
}

.people-list {
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s
}

.chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px
}

.chat-list li:hover {
  background: #efefef;
  cursor: pointer
}

.chat-list li.active {
  background: #efefef
}

.chat-list li .name {
  font-size: 15px
}

.chat-list img {
  width: 45px;
  border-radius: 50%
}



.about {
  float: left;
  padding-left: 8px
}

.status {
  color: #999;
  font-size: 13px
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff
}

.chat .chat-history ul {
  padding: 0
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px
}

.chat .chat-history .message-data {
  margin-bottom: 15px
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
  vertical-align: middle;
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .my-message {
  background: #efefef
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 20%
}
.float-right::after {
  left: 72%!important
}



.chat .chat-message {
  padding: 20px
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle
}

.online {
  color: #86c541
}

.offline {
  color: #e47297
}

.me {
  color: #1d8ecd
}

.float-right {
  float: right
}

.text-right {
  text-align: end;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0
}
.btn-check:active+.btn-outline-secondary, 
.btn-check:checked+.btn-outline-secondary, 
.btn-outline-secondary.active, 
.btn-outline-secondary.dropdown-toggle.show, 
.btn-outline-secondary:active, 
.btn-outline-secondary:hover {
  background-color:  red!important;
}
.message-container {
  height: 35vh;
  overflow-y:auto; 
  overflow-x: hidden;
}
@media screen and (max-width: 1440px) {
  .message-img {
    width: 400px;
  }
  .adaptive-message {
    max-width: 700px;
  }
  
}
@media screen and (max-width: 1080px) {
  .message-img {
    width: 400px;
  }
  .adaptive-message {
    max-width: 700px;
  }
  
}
.message-img-modal {
  width: 100%;
  max-width: 500px;
}

.message-input {
  font-size: large;
}

.modal-backdrop {
  z-index: 0!important;
}
.modal {
  overflow-y: clip;
  z-index: 99999!important;
}

.modal-content {
  padding: 1rem;
  width: 100%;
}

.message-input:focus {
  box-shadow: 0 0 0 .25rem rgba(253, 13, 13, 0.25);
  border: var(--bs-border-width) solid var(--bs-border-color);
}
.modal-message-text {
  position: absolute; 
  top: 115%; 
  margin: 0px auto; 
  left: 30%; 
  right: 30%; 
  background: rgb(39, 39, 39); 
  color: white; 
  font-weight: 100; 
  border-radius: 1rem; 
  padding: 2rem;
}

.msg-container-wrapper{
  position: absolute;
  top: 52vh;
  margin: 0px auto;
  left: 0;
  right: 0;
}

.msg-container-wrapper-modal {
  padding: 0!important;
  margin: 0!important;
  
}